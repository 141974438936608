import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { history } from '../sharedHistory';
import AGENT_BASIC_INFO from 'agent-basic-info';
import AGENT_BASIC_INFO_pckg from '../../node_modules/agent-basic-info/package.json';
import AGENTLOGIN from 'agentlogin';
import AGENTLOGIN_pckg from '../../node_modules/agentlogin/package.json';
import MENU_ACTIONS from 'menu-actions';
import MENU_ACTIONS_pckg from '../../node_modules/menu-actions/package.json';
import CUSTOMER_SEARCH_WIDGET from 'customer-search-widget';
import CUSTOMER_SEARCH_WIDGET_pckg from '../../node_modules/customer-search-widget/package.json';
import CUSTOMER_INFORMATION_BANNER from 'customer-information-banner';
import CUSTOMER_INFORMATION_BANNER_pckg from '../../node_modules/customer-information-banner/package.json';
import PRODUCTS_AND_SERVICES from 'products-and-services';
import PRODUCTS_AND_SERVICES_pckg from '../../node_modules/products-and-services/package.json';
import NAVIGATION_HEADER from 'navigation-header';
import NAVIGATION_HEADER_pckg from '../../node_modules/navigation-header/package.json';
import ERROR_MODAL from 'error-modal';
import ERROR_MODAL_pckg from '../../node_modules/error-modal/package.json';
import GENERIC_ACTIONS from 'generic-actions';
import GENERIC_ACTIONS_pckg from '../../node_modules/generic-actions/package.json';
import TOPUP from 'topup';
import TOPUP_pckg from '../../node_modules/topup/package.json';
import PLAN_TYPE_MODAL from 'plan-type-modal';
import PLAN_TYPE_MODAL_pckg from '../../node_modules/plan-type-modal/package.json';
import BUNDLE_DETAILS from 'bundle-details';
import BUNDLE_DETAILS_pckg from '../../node_modules/bundle-details/package.json';
import SERVICE_DETAILS_HEADER from 'service-details-header';
import SERVICE_DETAILS_HEADER_pckg from '../../node_modules/service-details-header/package.json';
import SERVICE_TAGS from 'service-tags';
import SERVICE_TAGS_pckg from '../../node_modules/service-tags/package.json';
import PLANS_CARD from 'plans-card';
import PLANS_CARD_pckg from '../../node_modules/plans-card/package.json';
import CONSUMPTION_CARD from 'consumption-card';
import CONSUMPTION_CARD_pckg from '../../node_modules/consumption-card/package.json';
import PEGA_OFFERS from 'pega-offers';
import PEGA_OFFERS_pckg from '../../node_modules/pega-offers/package.json';
import BARRING_MANAGEMENT from 'barring-management';
import BARRING_MANAGEMENT_pckg from '../../node_modules/barring-management/package.json';
import CUSTOMER_DASHBOARD_ADDONS from 'customer-dashboard-addons';
import CUSTOMER_DASHBOARD_ADDONS_pckg from '../../node_modules/customer-dashboard-addons/package.json';
import PROCESS_FLOW_STEPPER from 'process-flow-stepper';
import PROCESS_FLOW_STEPPER_pckg from '../../node_modules/process-flow-stepper/package.json';
import TOPUP_SELECTOR from 'topup-selector';
import TOPUP_SELECTOR_pckg from '../../node_modules/topup-selector/package.json';
import SIM_SELECTOR from 'sim-selector';
import SIM_SELECTOR_pckg from '../../node_modules/sim-selector/package.json';
import PRODUCT_SELECTOR from 'product-selector';
import PRODUCT_SELECTOR_pckg from '../../node_modules/product-selector/package.json';
import PEGA_DETAILS from 'pega-details';
import PEGA_DETAILS_pckg from '../../node_modules/pega-details/package.json';
import PRODUCT_OFFERING_DETAILS from 'product-offering-details';
import PRODUCT_OFFERING_DETAILS_pckg from '../../node_modules/product-offering-details/package.json';

import authentication from './authenticationReducer';
import config from './configReducer';
import language from './languageReducer'
import props from "./propReducer";
import pages from "./pagesReducer";

const AGENT_BASIC_INFO_reduxPerInstance = AGENT_BASIC_INFO_pckg['app-framework'] && AGENT_BASIC_INFO_pckg['app-framework'].reduxPerInstance;
const AGENTLOGIN_reduxPerInstance = AGENTLOGIN_pckg['app-framework'] && AGENTLOGIN_pckg['app-framework'].reduxPerInstance;
const MENU_ACTIONS_reduxPerInstance = MENU_ACTIONS_pckg['app-framework'] && MENU_ACTIONS_pckg['app-framework'].reduxPerInstance;
const CUSTOMER_SEARCH_WIDGET_reduxPerInstance = CUSTOMER_SEARCH_WIDGET_pckg['app-framework'] && CUSTOMER_SEARCH_WIDGET_pckg['app-framework'].reduxPerInstance;
const CUSTOMER_INFORMATION_BANNER_reduxPerInstance = CUSTOMER_INFORMATION_BANNER_pckg['app-framework'] && CUSTOMER_INFORMATION_BANNER_pckg['app-framework'].reduxPerInstance;
const PRODUCTS_AND_SERVICES_reduxPerInstance = PRODUCTS_AND_SERVICES_pckg['app-framework'] && PRODUCTS_AND_SERVICES_pckg['app-framework'].reduxPerInstance;
const NAVIGATION_HEADER_reduxPerInstance = NAVIGATION_HEADER_pckg['app-framework'] && NAVIGATION_HEADER_pckg['app-framework'].reduxPerInstance;
const ERROR_MODAL_reduxPerInstance = ERROR_MODAL_pckg['app-framework'] && ERROR_MODAL_pckg['app-framework'].reduxPerInstance;
const GENERIC_ACTIONS_reduxPerInstance = GENERIC_ACTIONS_pckg['app-framework'] && GENERIC_ACTIONS_pckg['app-framework'].reduxPerInstance;
const TOPUP_reduxPerInstance = TOPUP_pckg['app-framework'] && TOPUP_pckg['app-framework'].reduxPerInstance;
const PLAN_TYPE_MODAL_reduxPerInstance = PLAN_TYPE_MODAL_pckg['app-framework'] && PLAN_TYPE_MODAL_pckg['app-framework'].reduxPerInstance;
const BUNDLE_DETAILS_reduxPerInstance = BUNDLE_DETAILS_pckg['app-framework'] && BUNDLE_DETAILS_pckg['app-framework'].reduxPerInstance;
const SERVICE_DETAILS_HEADER_reduxPerInstance = SERVICE_DETAILS_HEADER_pckg['app-framework'] && SERVICE_DETAILS_HEADER_pckg['app-framework'].reduxPerInstance;
const SERVICE_TAGS_reduxPerInstance = SERVICE_TAGS_pckg['app-framework'] && SERVICE_TAGS_pckg['app-framework'].reduxPerInstance;
const PLANS_CARD_reduxPerInstance = PLANS_CARD_pckg['app-framework'] && PLANS_CARD_pckg['app-framework'].reduxPerInstance;
const CONSUMPTION_CARD_reduxPerInstance = CONSUMPTION_CARD_pckg['app-framework'] && CONSUMPTION_CARD_pckg['app-framework'].reduxPerInstance;
const PEGA_OFFERS_reduxPerInstance = PEGA_OFFERS_pckg['app-framework'] && PEGA_OFFERS_pckg['app-framework'].reduxPerInstance;
const BARRING_MANAGEMENT_reduxPerInstance = BARRING_MANAGEMENT_pckg['app-framework'] && BARRING_MANAGEMENT_pckg['app-framework'].reduxPerInstance;
const CUSTOMER_DASHBOARD_ADDONS_reduxPerInstance = CUSTOMER_DASHBOARD_ADDONS_pckg['app-framework'] && CUSTOMER_DASHBOARD_ADDONS_pckg['app-framework'].reduxPerInstance;
const PROCESS_FLOW_STEPPER_reduxPerInstance = PROCESS_FLOW_STEPPER_pckg['app-framework'] && PROCESS_FLOW_STEPPER_pckg['app-framework'].reduxPerInstance;
const TOPUP_SELECTOR_reduxPerInstance = TOPUP_SELECTOR_pckg['app-framework'] && TOPUP_SELECTOR_pckg['app-framework'].reduxPerInstance;
const SIM_SELECTOR_reduxPerInstance = SIM_SELECTOR_pckg['app-framework'] && SIM_SELECTOR_pckg['app-framework'].reduxPerInstance;
const PRODUCT_SELECTOR_reduxPerInstance = PRODUCT_SELECTOR_pckg['app-framework'] && PRODUCT_SELECTOR_pckg['app-framework'].reduxPerInstance;
const PEGA_DETAILS_reduxPerInstance = PEGA_DETAILS_pckg['app-framework'] && PEGA_DETAILS_pckg['app-framework'].reduxPerInstance;
const PRODUCT_OFFERING_DETAILS_reduxPerInstance = PRODUCT_OFFERING_DETAILS_pckg['app-framework'] && PRODUCT_OFFERING_DETAILS_pckg['app-framework'].reduxPerInstance;

const identityReducer = (state = null, action) => state;

const systemReducers = {
  router: connectRouter(history),
  authentication,
  pages,
  language,
  config,
  props
};

const singleReducer = (reduxPerInstance, reducer) => {
  if(!reducer) return null;
  if(reduxPerInstance) return null;
  return reducer;
};

const perInstanceReducer = (pckg, instanceId, reduxPerInstance, reducer) => {
  if(!reducer) return null;
  if(!reduxPerInstance) return null;
  return (state, action) => {
    if(action.type.startsWith(`@${pckg}/`) || state === undefined) {
      if(action.instanceId) {
        if(Array.isArray(action.instanceId) && action.instanceId.includes(instanceId)) {
          return reducer(state, action);
        } else if (action.instanceId === instanceId) {
          return reducer(state, action);
        } else {
          return state;
        }
      } else {
        return reducer(state, action);
      }
    } else {
      return state;
    }
  };
};

const singleReducersInit = {
  'agent-basic-info': singleReducer(AGENT_BASIC_INFO_reduxPerInstance, AGENT_BASIC_INFO.reducer),
  'agentlogin': singleReducer(AGENTLOGIN_reduxPerInstance, AGENTLOGIN.reducer),
  'menu-actions': singleReducer(MENU_ACTIONS_reduxPerInstance, MENU_ACTIONS.reducer),
  'customer-search-widget': singleReducer(CUSTOMER_SEARCH_WIDGET_reduxPerInstance, CUSTOMER_SEARCH_WIDGET.reducer),
  'customer-information-banner': singleReducer(CUSTOMER_INFORMATION_BANNER_reduxPerInstance, CUSTOMER_INFORMATION_BANNER.reducer),
  'products-and-services': singleReducer(PRODUCTS_AND_SERVICES_reduxPerInstance, PRODUCTS_AND_SERVICES.reducer),
  'navigation-header': singleReducer(NAVIGATION_HEADER_reduxPerInstance, NAVIGATION_HEADER.reducer),
  'error-modal': singleReducer(ERROR_MODAL_reduxPerInstance, ERROR_MODAL.reducer),
  'generic-actions': singleReducer(GENERIC_ACTIONS_reduxPerInstance, GENERIC_ACTIONS.reducer),
  'topup': singleReducer(TOPUP_reduxPerInstance, TOPUP.reducer),
  'plan-type-modal': singleReducer(PLAN_TYPE_MODAL_reduxPerInstance, PLAN_TYPE_MODAL.reducer),
  'bundle-details': singleReducer(BUNDLE_DETAILS_reduxPerInstance, BUNDLE_DETAILS.reducer),
  'service-details-header': singleReducer(SERVICE_DETAILS_HEADER_reduxPerInstance, SERVICE_DETAILS_HEADER.reducer),
  'service-tags': singleReducer(SERVICE_TAGS_reduxPerInstance, SERVICE_TAGS.reducer),
  'plans-card': singleReducer(PLANS_CARD_reduxPerInstance, PLANS_CARD.reducer),
  'consumption-card': singleReducer(CONSUMPTION_CARD_reduxPerInstance, CONSUMPTION_CARD.reducer),
  'pega-offers': singleReducer(PEGA_OFFERS_reduxPerInstance, PEGA_OFFERS.reducer),
  'barring-management': singleReducer(BARRING_MANAGEMENT_reduxPerInstance, BARRING_MANAGEMENT.reducer),
  'customer-dashboard-addons': singleReducer(CUSTOMER_DASHBOARD_ADDONS_reduxPerInstance, CUSTOMER_DASHBOARD_ADDONS.reducer),
  'process-flow-stepper': singleReducer(PROCESS_FLOW_STEPPER_reduxPerInstance, PROCESS_FLOW_STEPPER.reducer),
  'topup-selector': singleReducer(TOPUP_SELECTOR_reduxPerInstance, TOPUP_SELECTOR.reducer),
  'sim-selector': singleReducer(SIM_SELECTOR_reduxPerInstance, SIM_SELECTOR.reducer),
  'product-selector': singleReducer(PRODUCT_SELECTOR_reduxPerInstance, PRODUCT_SELECTOR.reducer),
  'pega-details': singleReducer(PEGA_DETAILS_reduxPerInstance, PEGA_DETAILS.reducer),
  'product-offering-details': singleReducer(PRODUCT_OFFERING_DETAILS_reduxPerInstance, PRODUCT_OFFERING_DETAILS.reducer),
};

const userReducersPerInstanceInit = {
  'agent-basic-info': AGENT_BASIC_INFO.reducer && AGENT_BASIC_INFO_reduxPerInstance && combineReducers(Object.fromEntries(Object.entries({
    'ccf5461d_d89a_431b_8360_b3fbdc945aee': perInstanceReducer('agent-basic-info', 'ccf5461d-d89a-431b-8360-b3fbdc945aee', AGENT_BASIC_INFO_pckg['app-framework'], AGENT_BASIC_INFO.reducer),
    'ccf5461d_d89a_431b_8360_b3fbdc945aee': perInstanceReducer('agent-basic-info', 'ccf5461d-d89a-431b-8360-b3fbdc945aee', AGENT_BASIC_INFO_pckg['app-framework'], AGENT_BASIC_INFO.reducer),
  }).filter(pair => pair[1]))),
  'agentlogin': AGENTLOGIN.reducer && AGENTLOGIN_reduxPerInstance && combineReducers(Object.fromEntries(Object.entries({
    '6115fe49_eed0_4398_beb7_3eb4b62b5e63': perInstanceReducer('agentlogin', '6115fe49-eed0-4398-beb7-3eb4b62b5e63', AGENTLOGIN_pckg['app-framework'], AGENTLOGIN.reducer),
    '6115fe49_eed0_4398_beb7_3eb4b62b5e63': perInstanceReducer('agentlogin', '6115fe49-eed0-4398-beb7-3eb4b62b5e63', AGENTLOGIN_pckg['app-framework'], AGENTLOGIN.reducer),
  }).filter(pair => pair[1]))),
  'menu-actions': MENU_ACTIONS.reducer && MENU_ACTIONS_reduxPerInstance && combineReducers(Object.fromEntries(Object.entries({
    '75260c81_990c_46a4_be7b_1e53781ae1d5': perInstanceReducer('menu-actions', '75260c81-990c-46a4-be7b-1e53781ae1d5', MENU_ACTIONS_pckg['app-framework'], MENU_ACTIONS.reducer),
    'ff297647_f47b_4810_8e6f_3374491713a7': perInstanceReducer('menu-actions', 'ff297647-f47b-4810-8e6f-3374491713a7', MENU_ACTIONS_pckg['app-framework'], MENU_ACTIONS.reducer),
    'ff297647_f47b_4810_8e6f_3374491713a7': perInstanceReducer('menu-actions', 'ff297647-f47b-4810-8e6f-3374491713a7', MENU_ACTIONS_pckg['app-framework'], MENU_ACTIONS.reducer),
    '75260c81_990c_46a4_be7b_1e53781ae1d5': perInstanceReducer('menu-actions', '75260c81-990c-46a4-be7b-1e53781ae1d5', MENU_ACTIONS_pckg['app-framework'], MENU_ACTIONS.reducer),
    'ff297647_f47b_4810_8e6f_3374491713a7': perInstanceReducer('menu-actions', 'ff297647-f47b-4810-8e6f-3374491713a7', MENU_ACTIONS_pckg['app-framework'], MENU_ACTIONS.reducer),
  }).filter(pair => pair[1]))),
  'customer-search-widget': CUSTOMER_SEARCH_WIDGET.reducer && CUSTOMER_SEARCH_WIDGET_reduxPerInstance && combineReducers(Object.fromEntries(Object.entries({
    '53ccdb1e_23d7_42fc_bdd7_34f87157b10c': perInstanceReducer('customer-search-widget', '53ccdb1e-23d7-42fc-bdd7-34f87157b10c', CUSTOMER_SEARCH_WIDGET_pckg['app-framework'], CUSTOMER_SEARCH_WIDGET.reducer),
  }).filter(pair => pair[1]))),
  'customer-information-banner': CUSTOMER_INFORMATION_BANNER.reducer && CUSTOMER_INFORMATION_BANNER_reduxPerInstance && combineReducers(Object.fromEntries(Object.entries({
    '1431a3a1_e6ad_4899_a38d_58379315881d': perInstanceReducer('customer-information-banner', '1431a3a1-e6ad-4899-a38d-58379315881d', CUSTOMER_INFORMATION_BANNER_pckg['app-framework'], CUSTOMER_INFORMATION_BANNER.reducer),
    '1431a3a1_e6ad_4899_a38d_58379315881d': perInstanceReducer('customer-information-banner', '1431a3a1-e6ad-4899-a38d-58379315881d', CUSTOMER_INFORMATION_BANNER_pckg['app-framework'], CUSTOMER_INFORMATION_BANNER.reducer),
    '1431a3a1_e6ad_4899_a38d_58379315881d': perInstanceReducer('customer-information-banner', '1431a3a1-e6ad-4899-a38d-58379315881d', CUSTOMER_INFORMATION_BANNER_pckg['app-framework'], CUSTOMER_INFORMATION_BANNER.reducer),
  }).filter(pair => pair[1]))),
  'products-and-services': PRODUCTS_AND_SERVICES.reducer && PRODUCTS_AND_SERVICES_reduxPerInstance && combineReducers(Object.fromEntries(Object.entries({
    'fa1dfba1_8df2_4be8_9dad_268c99038e58': perInstanceReducer('products-and-services', 'fa1dfba1-8df2-4be8-9dad-268c99038e58', PRODUCTS_AND_SERVICES_pckg['app-framework'], PRODUCTS_AND_SERVICES.reducer),
    'fa1dfba1_8df2_4be8_9dad_268c99038e58': perInstanceReducer('products-and-services', 'fa1dfba1-8df2-4be8-9dad-268c99038e58', PRODUCTS_AND_SERVICES_pckg['app-framework'], PRODUCTS_AND_SERVICES.reducer),
    'fa1dfba1_8df2_4be8_9dad_268c99038e58': perInstanceReducer('products-and-services', 'fa1dfba1-8df2-4be8-9dad-268c99038e58', PRODUCTS_AND_SERVICES_pckg['app-framework'], PRODUCTS_AND_SERVICES.reducer),
  }).filter(pair => pair[1]))),
  'navigation-header': NAVIGATION_HEADER.reducer && NAVIGATION_HEADER_reduxPerInstance && combineReducers(Object.fromEntries(Object.entries({
    'bed71760_7617_45dd_b63d_8c762759429b': perInstanceReducer('navigation-header', 'bed71760-7617-45dd-b63d-8c762759429b', NAVIGATION_HEADER_pckg['app-framework'], NAVIGATION_HEADER.reducer),
  }).filter(pair => pair[1]))),
  'error-modal': ERROR_MODAL.reducer && ERROR_MODAL_reduxPerInstance && combineReducers(Object.fromEntries(Object.entries({
    '3261dea2_29b4_412e_a0f6_3028badd6f8c': perInstanceReducer('error-modal', '3261dea2-29b4-412e-a0f6-3028badd6f8c', ERROR_MODAL_pckg['app-framework'], ERROR_MODAL.reducer),
  }).filter(pair => pair[1]))),
  'generic-actions': GENERIC_ACTIONS.reducer && GENERIC_ACTIONS_reduxPerInstance && combineReducers(Object.fromEntries(Object.entries({
    '71301cc0_0208_4531_b9dc_d99a729da16f': perInstanceReducer('generic-actions', '71301cc0-0208-4531-b9dc-d99a729da16f', GENERIC_ACTIONS_pckg['app-framework'], GENERIC_ACTIONS.reducer),
  }).filter(pair => pair[1]))),
  'topup': TOPUP.reducer && TOPUP_reduxPerInstance && combineReducers(Object.fromEntries(Object.entries({
    '2db3dd57_b0cd_4d3c_8957_f0980cc98ab3': perInstanceReducer('topup', '2db3dd57-b0cd-4d3c-8957-f0980cc98ab3', TOPUP_pckg['app-framework'], TOPUP.reducer),
  }).filter(pair => pair[1]))),
  'plan-type-modal': PLAN_TYPE_MODAL.reducer && PLAN_TYPE_MODAL_reduxPerInstance && combineReducers(Object.fromEntries(Object.entries({
    '8688e71e_6c65_46b1_af34_5153e6230133': perInstanceReducer('plan-type-modal', '8688e71e-6c65-46b1-af34-5153e6230133', PLAN_TYPE_MODAL_pckg['app-framework'], PLAN_TYPE_MODAL.reducer),
  }).filter(pair => pair[1]))),
  'bundle-details': BUNDLE_DETAILS.reducer && BUNDLE_DETAILS_reduxPerInstance && combineReducers(Object.fromEntries(Object.entries({
    '71944313_7cae_440f_90a5_c00fcf8cd938': perInstanceReducer('bundle-details', '71944313-7cae-440f-90a5-c00fcf8cd938', BUNDLE_DETAILS_pckg['app-framework'], BUNDLE_DETAILS.reducer),
    'be620645_f303_4c38_9c77_a790c13c588f': perInstanceReducer('bundle-details', 'be620645-f303-4c38-9c77-a790c13c588f', BUNDLE_DETAILS_pckg['app-framework'], BUNDLE_DETAILS.reducer),
  }).filter(pair => pair[1]))),
  'service-details-header': SERVICE_DETAILS_HEADER.reducer && SERVICE_DETAILS_HEADER_reduxPerInstance && combineReducers(Object.fromEntries(Object.entries({
    '33b587eb_73d8_4517_906b_d8886aadfbb4': perInstanceReducer('service-details-header', '33b587eb-73d8-4517-906b-d8886aadfbb4', SERVICE_DETAILS_HEADER_pckg['app-framework'], SERVICE_DETAILS_HEADER.reducer),
  }).filter(pair => pair[1]))),
  'service-tags': SERVICE_TAGS.reducer && SERVICE_TAGS_reduxPerInstance && combineReducers(Object.fromEntries(Object.entries({
    'bc3d3779_fc78_43be_9705_c370f041eda8': perInstanceReducer('service-tags', 'bc3d3779-fc78-43be-9705-c370f041eda8', SERVICE_TAGS_pckg['app-framework'], SERVICE_TAGS.reducer),
  }).filter(pair => pair[1]))),
  'plans-card': PLANS_CARD.reducer && PLANS_CARD_reduxPerInstance && combineReducers(Object.fromEntries(Object.entries({
    '5a319dd8_aa24_428f_9af0_5ff2aedad3dd': perInstanceReducer('plans-card', '5a319dd8-aa24-428f-9af0-5ff2aedad3dd', PLANS_CARD_pckg['app-framework'], PLANS_CARD.reducer),
  }).filter(pair => pair[1]))),
  'consumption-card': CONSUMPTION_CARD.reducer && CONSUMPTION_CARD_reduxPerInstance && combineReducers(Object.fromEntries(Object.entries({
    '194a7bea_c89a_40b5_ac6a_1190397bb9b2': perInstanceReducer('consumption-card', '194a7bea-c89a-40b5-ac6a-1190397bb9b2', CONSUMPTION_CARD_pckg['app-framework'], CONSUMPTION_CARD.reducer),
  }).filter(pair => pair[1]))),
  'pega-offers': PEGA_OFFERS.reducer && PEGA_OFFERS_reduxPerInstance && combineReducers(Object.fromEntries(Object.entries({
    'bf49b19b_8da8_4480_9cae_b4c6962cc821': perInstanceReducer('pega-offers', 'bf49b19b-8da8-4480-9cae-b4c6962cc821', PEGA_OFFERS_pckg['app-framework'], PEGA_OFFERS.reducer),
  }).filter(pair => pair[1]))),
  'barring-management': BARRING_MANAGEMENT.reducer && BARRING_MANAGEMENT_reduxPerInstance && combineReducers(Object.fromEntries(Object.entries({
    '11aa9d67_9344_4c8b_8515_83b1f8175f85': perInstanceReducer('barring-management', '11aa9d67-9344-4c8b-8515-83b1f8175f85', BARRING_MANAGEMENT_pckg['app-framework'], BARRING_MANAGEMENT.reducer),
  }).filter(pair => pair[1]))),
  'customer-dashboard-addons': CUSTOMER_DASHBOARD_ADDONS.reducer && CUSTOMER_DASHBOARD_ADDONS_reduxPerInstance && combineReducers(Object.fromEntries(Object.entries({
    '70e12108_1b83_41ca_85e7_1892f1a944d0': perInstanceReducer('customer-dashboard-addons', '70e12108-1b83-41ca-85e7-1892f1a944d0', CUSTOMER_DASHBOARD_ADDONS_pckg['app-framework'], CUSTOMER_DASHBOARD_ADDONS.reducer),
  }).filter(pair => pair[1]))),
  'process-flow-stepper': PROCESS_FLOW_STEPPER.reducer && PROCESS_FLOW_STEPPER_reduxPerInstance && combineReducers(Object.fromEntries(Object.entries({
    'c7c7a1fc_9185_43fc_a660_e15b6335d692': perInstanceReducer('process-flow-stepper', 'c7c7a1fc-9185-43fc-a660-e15b6335d692', PROCESS_FLOW_STEPPER_pckg['app-framework'], PROCESS_FLOW_STEPPER.reducer),
  }).filter(pair => pair[1]))),
  'topup-selector': TOPUP_SELECTOR.reducer && TOPUP_SELECTOR_reduxPerInstance && combineReducers(Object.fromEntries(Object.entries({
    'ddcd58b0_99fc_4854_a21c_54c63e77d593': perInstanceReducer('topup-selector', 'ddcd58b0-99fc-4854-a21c-54c63e77d593', TOPUP_SELECTOR_pckg['app-framework'], TOPUP_SELECTOR.reducer),
  }).filter(pair => pair[1]))),
  'sim-selector': SIM_SELECTOR.reducer && SIM_SELECTOR_reduxPerInstance && combineReducers(Object.fromEntries(Object.entries({
    '9211f971_4bec_44e3_ad65_202fbe5e720b': perInstanceReducer('sim-selector', '9211f971-4bec-44e3-ad65-202fbe5e720b', SIM_SELECTOR_pckg['app-framework'], SIM_SELECTOR.reducer),
  }).filter(pair => pair[1]))),
  'product-selector': PRODUCT_SELECTOR.reducer && PRODUCT_SELECTOR_reduxPerInstance && combineReducers(Object.fromEntries(Object.entries({
    '397a70c0_18c6_4dec_8369_8d9170b280eb': perInstanceReducer('product-selector', '397a70c0-18c6-4dec-8369-8d9170b280eb', PRODUCT_SELECTOR_pckg['app-framework'], PRODUCT_SELECTOR.reducer),
    'c4f07cdb_702d_400e_8fc9_a3bbc10e28be': perInstanceReducer('product-selector', 'c4f07cdb-702d-400e-8fc9-a3bbc10e28be', PRODUCT_SELECTOR_pckg['app-framework'], PRODUCT_SELECTOR.reducer),
  }).filter(pair => pair[1]))),
  'pega-details': PEGA_DETAILS.reducer && PEGA_DETAILS_reduxPerInstance && combineReducers(Object.fromEntries(Object.entries({
    '9a018acf_d274_4b0e_b831_1360a3081d56': perInstanceReducer('pega-details', '9a018acf-d274-4b0e-b831-1360a3081d56', PEGA_DETAILS_pckg['app-framework'], PEGA_DETAILS.reducer),
  }).filter(pair => pair[1]))),
  'product-offering-details': PRODUCT_OFFERING_DETAILS.reducer && PRODUCT_OFFERING_DETAILS_reduxPerInstance && combineReducers(Object.fromEntries(Object.entries({
    'fc0ff7fd_79b0_4c55_9ba6_b4e07904b952': perInstanceReducer('product-offering-details', 'fc0ff7fd-79b0-4c55-9ba6-b4e07904b952', PRODUCT_OFFERING_DETAILS_pckg['app-framework'], PRODUCT_OFFERING_DETAILS.reducer),
  }).filter(pair => pair[1]))),
};

const userReducersSingle = Object.fromEntries(Object.entries(singleReducersInit).filter(pair => pair[1]));
const userReducersPerInstance = Object.fromEntries(Object.entries(userReducersPerInstanceInit).filter(pair => pair[1]));

export default combineReducers({
  ...systemReducers,
  ...userReducersSingle,
  ...userReducersPerInstance,
});
